import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EvaluationForm from './EvaluationForm';

const TeacherEvaluation = () => {
  const [evaluations, setEvaluations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  useEffect(() => {
    const fetchEvaluations = async () => {
      const response = await axios.get('/api/evaluations');
      setEvaluations(response.data);
    };
    fetchEvaluations();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const filteredEvaluations = evaluations.filter((evaluation) =>
    evaluation.teacher.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedEvaluations = sortColumn
    ? filteredEvaluations.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      })
    : filteredEvaluations;

  const itemsPerPage = 10;
  const totalPages = Math.ceil(sortedEvaluations.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedEvaluations = sortedEvaluations.slice(startIndex, endIndex);

  return (
    <div>
      <h2>Évaluations des enseignants</h2>
      <input
        type="text"
        placeholder="Rechercher une évaluation..."
        value={searchTerm}
        onChange={handleSearch}
      />
      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort('teacher.name')}>Enseignant</th>
            <th onClick={() => handleSort('criteria')}>Critères</th>
            <th onClick={() => handleSort('date')}>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {displayedEvaluations.map((evaluation) => (
            <tr key={evaluation._id}>
              <td>{evaluation.teacher.name}</td>
              <td>{evaluation.criteria.join(', ')}</td>
              <td>{new Date(evaluation.date).toLocaleDateString()}</td>
              <td>
                <button>Modifier</button>
                <button>Supprimer</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
          Précédent
        </button>
        <span>
          Page {currentPage} sur {totalPages}
        </span>
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Suivant
        </button>
      </div>
      <EvaluationForm />
    </div>
  );
};

export default TeacherEvaluation;