import React, { useState } from 'react';
import axios from 'axios';

const TeacherForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subjects, setSubjects] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const teacher = {
      name,
      email,
      subjects: subjects.split(',').map((subject) => subject.trim()),
    };
    await axios.post('/api/teachers', teacher);
    setName('');
    setEmail('');
    setSubjects('');
  };

  return (
    <div>
      <h3>Ajouter un nouvel enseignant</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Nom :</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="email">Email :</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="subjects">Matières enseignées (séparées par des virgules) :</label>
          <input
            type="text"
            id="subjects"
            value={subjects}
            onChange={(event) => setSubjects(event.target.value)}
          />
        </div>
        <button type="submit">Enregistrer</button>
      </form>
    </div>
  );
};

export default TeacherForm;