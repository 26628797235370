import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CourseForm from './CourseForm';

const CourseScheduling = () => {
  const [courses, setCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      const response = await axios.get('/api/scheduling');
      setCourses(response.data);
    };
    fetchCourses();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const filteredCourses = courses.filter((course) =>
    course.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedCourses = sortColumn
    ? filteredCourses.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      })
    : filteredCourses;

  const itemsPerPage = 10;
  const totalPages = Math.ceil(sortedCourses.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedCourses = sortedCourses.slice(startIndex, endIndex);

  return (
    <div>
      <h2>Emplois du temps des cours</h2>
      <input
        type="text"
        placeholder="Rechercher un cours..."
        value={searchTerm}
        onChange={handleSearch}
      />
      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort('title')}>Titre</th>
            <th onClick={() => handleSort('teacher.name')}>Enseignant</th>
            <th onClick={() => handleSort('level')}>Niveau</th>
            <th>Horaire</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {displayedCourses.map((course) => (
            <tr key={course._id}>
              <td>{course.title}</td>
              <td>{course.teacher.name}</td>
              <td>{course.level}</td>
              <td>{/* Afficher l'horaire ici */}</td>
              <td>
                <button>Modifier</button>
                <button>Supprimer</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
          Précédent
        </button>
        <span>
          Page {currentPage} sur {totalPages}
        </span>
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Suivant
        </button>
      </div>
      <CourseForm />
    </div>
  );
};

export default CourseScheduling;