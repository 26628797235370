import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ExamManagement = () => {
  const [exams, setExams] = useState([]);

  useEffect(() => {
    const fetchExams = async () => {
      const response = await axios.get('/api/exams');
      setExams(response.data);
    };
    fetchExams();
  }, []);

  return (
    <div>
      <h2>Gestion des examens</h2>
      <ul>
        {exams.map((exam) => (
          <li key={exam._id}>
            <h3>{exam.title}</h3>
            <p>Description : {exam.description}</p>
            <p>Date : {new Date(exam.date).toLocaleDateString()}</p>
            <p>Cours concernés : {exam.courses.map((course) => course.title).join(', ')}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ExamManagement;