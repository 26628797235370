// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TeacherList from './components/TeacherList';
import TeacherEvaluation from './components/TeacherEvaluation';
import TrainingManagement from './components/TrainingManagement';
import CourseScheduling from './components/CourseScheduling';
import ExamManagement from './components/ExamManagement';
import Communication from './components/Communication';

function App() {
    return (
        <Router>
            <div>
                <nav>
                    {/* Navigation links */}
                </nav>
                <Routes>
                    <Route path="/teachers" element={<TeacherList />} />
                    <Route path="/evaluations" element={<TeacherEvaluation />} />
                    <Route path="/training" element={<TrainingManagement />} />
                    <Route path="/scheduling" element={<CourseScheduling />} />
                    <Route path="/exams" element={<ExamManagement />} />
                    <Route path="/communication" element={<Communication />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;