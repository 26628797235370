import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Communication = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      const response = await axios.get('/api/messages');
      setMessages(response.data);
    };
    fetchMessages();
  }, []);

  return (
    <div>
      <h2>Communication</h2>
      <ul>
        {messages.map((message) => (
          <li key={message._id}>
            <h3>{message.sender.name}</h3>
            <p>{message.content}</p>
            <p>Envoyé le : {new Date(message.timestamp).toLocaleString()}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Communication;