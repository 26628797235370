import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EvaluationForm = () => {
  const [teacher, setTeacher] = useState('');
  const [criteria, setCriteria] = useState('');
  const [observations, setObservations] = useState('');
  const [date, setDate] = useState('');
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    const fetchTeachers = async () => {
      const response = await axios.get('/api/teachers');
      setTeachers(response.data);
    };
    fetchTeachers();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const evaluation = {
      teacher,
      criteria: criteria.split(',').map((criterion) => criterion.trim()),
      observations,
      date,
    };
    await axios.post('/api/evaluations', evaluation);
    setTeacher('');
    setCriteria('');
    setObservations('');
    setDate('');
  };

  return (
    <div>
      <h3>Ajouter une nouvelle évaluation</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="teacher">Enseignant :</label>
          <select
            id="teacher"
            value={teacher}
            onChange={(event) => setTeacher(event.target.value)}
          >
            <option value="">Sélectionner un enseignant</option>
            {teachers.map((teacher) => (
              <option key={teacher._id} value={teacher._id}>
                {teacher.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="criteria">Critères (séparés par des virgules) :</label>
          <input
            type="text"
            id="criteria"
            value={criteria}
            onChange={(event) => setCriteria(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="observations">Observations :</label>
          <textarea
            id="observations"
            value={observations}
            onChange={(event) => setObservations(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="date">Date :</label>
          <input
            type="date"
            id="date"
            value={date}
            onChange={(event) => setDate(event.target.value)}
          />
        </div>
        <button type="submit">Enregistrer</button>
      </form>
    </div>
  );
};

export default EvaluationForm;