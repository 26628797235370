import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TrainingForm = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [attendees, setAttendees] = useState([]);
  const [trainer, setTrainer] = useState('');
  const [materials, setMaterials] = useState('');
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    const fetchTeachers = async () => {
      const response = await axios.get('/api/teachers');
      setTeachers(response.data);
    };
    fetchTeachers();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const training = {
      title,
      description,
      date,
      attendees,
      trainer,
      materials: materials.split(',').map((material) => material.trim()),
    };
    await axios.post('/api/training', training);
    setTitle('');
    setDescription('');
    setDate('');
    setAttendees([]);
    setTrainer('');
    setMaterials('');
  };

  return (
    <div>
      <h3>Ajouter une nouvelle formation</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title">Titre :</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="description">Description :</label>
          <textarea
            id="description"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="date">Date :</label>
          <input
            type="date"
            id="date"
            value={date}
            onChange={(event) => setDate(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="attendees">Participants :</label>
          <select
            multiple
            id="attendees"
            value={attendees}
            onChange={(event) => setAttendees(Array.from(event.target.selectedOptions, (option) => option.value))}
          >
            <option value="">Sélectionner les participants</option>
            {teachers.map((teacher) => (
              <option key={teacher._id} value={teacher._id}>
                {teacher.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="trainer">Formateur :</label>
          <select
            id="trainer"
            value={trainer}
            onChange={(event) => setTrainer(event.target.value)}
          >
            <option value="">Sélectionner un formateur</option>
            {teachers.map((teacher) => (
              <option key={teacher._id} value={teacher._id}>
                {teacher.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="materials">Ressources (séparées par des virgules) :</label>
          <input
            type="text"
            id="materials"
            value={materials}
            onChange={(event) => setMaterials(event.target.value)}
          />
        </div>
        <button type="submit">Enregistrer</button>
      </form>
    </div>
  );
};

export default TrainingForm;