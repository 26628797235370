import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CourseForm = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [teacher, setTeacher] = useState('');
  const [level, setLevel] = useState('');
  const [schedule, setSchedule] = useState('');
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    const fetchTeachers = async () => {
      const response = await axios.get('/api/teachers');
      setTeachers(response.data);
    };
    fetchTeachers();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const course = {
      title,
      description,
      teacher,
      level,
      schedule,
    };
    await axios.post('/api/scheduling', course);
    setTitle('');
    setDescription('');
    setTeacher('');
    setLevel('');
    setSchedule('');
  };

  return (
    <div>
      <h3>Ajouter un nouveau cours</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title">Titre :</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="description">Description :</label>
          <textarea
            id="description"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="teacher">Enseignant :</label>
          <select
            id="teacher"
            value={teacher}
            onChange={(event) => setTeacher(event.target.value)}
          >
            <option value="">Sélectionner un enseignant</option>
            {teachers.map((teacher) => (
              <option key={teacher._id} value={teacher._id}>
                {teacher.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="level">Niveau :</label>
          <input
            type="text"
            id="level"
            value={level}
            onChange={(event) => setLevel(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="schedule">Horaire :</label>
          <input
            type="text"
            id="schedule"
            value={schedule}
            onChange={(event) => setSchedule(event.target.value)}
          />
        </div>
        <button type="submit">Enregistrer</button>
      </form>
    </div>
  );
};

export default CourseForm;